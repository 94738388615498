<template>
    <ion-header class="ion-no-border ">
        <ion-toolbar class="" color="primary">
            <ion-title class="text-lg">Prévisualisation du livre</ion-title>
            <ion-button slot="end" fill="clear" @click="dismiss" size="large" color="white">
                <ion-icon slot="icon-only" :icon="closeIcon"></ion-icon>
            </ion-button>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <div v-if="this.bookId" class="h-full w-full bg-primary-light relative">
            <iframe :src="bookPageTemplate" class="w-full h-full mx-auto"></iframe>
            <div v-if="loading" class="bg-white/40 absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                <ion-spinner class="size-12"></ion-spinner>
            </div>
        </div>
        <div class="absolute top-0 bottom-0 left-0 right-0"></div>
    </ion-content>

</template>

<script>
import {
    IonButton,
    IonContent,
    IonHeader, IonIcon, IonToolbar, IonTitle,
    modalController, IonSpinner
} from '@ionic/vue';
import { defineComponent } from 'vue';

import {
    close as closeIcon,
} from 'ionicons/icons';
import { API_URL } from "@/constants/UrlConstant";

export default defineComponent({
    name: 'ModalBookPreview',
    components: {
        IonSpinner,
        IonButton,
        IonToolbar,
        IonContent,
        IonHeader,
        IonTitle,
        IonIcon,
    },
    props: {
        bookId: [String, Number],
        page: [String,Number],
    },
    data() {
        return {
            closeIcon,
            loading: true,
        };
    },
    mounted() {
        setTimeout(() => this.loading = false, 3000);
    },
    methods: {

        dismiss: function () {
            return modalController.dismiss(null, 'cancel');
        },

    },

    computed: {
        bookPageTemplate() {
            if (!this.bookId) {
                return null;
            }

            return API_URL + 'house/' + this.$route.params.house + '/book/' + this.bookId + '/image?page=' + this.page + '&token=' + localStorage.getItem('access_token') + '#toolbar=0';
        }
    }
})
;
</script>

<style lang="scss" scoped>
ion-content, ion-header, ion-toolbar {
    --background: var(--ion-color-primary);
    background-color: var(--ion-color-primary);
}

ion-footer {
    --background: var(--ion-color-tertiary);
    background-color: var(--ion-color-tertiary);
}
</style>