<template>

    <div class="modify-component">

        <div class="inputs-container" :class="[activated ? 'activated' : null]">

            <div v-for="(field, index) in activity.fields" :key="index"
                 class="input "
                 :class="[ field.type === 'title' || field.type === 'secret' ? 'bg-primary' : 'bg-white', field.type === 'with-who' || field.type === 'secret' ? 'mt-16': 'mt-2' ]">
                <p class="text-lg text-center block mb-2"
                   :class="[ field.type === 'title' || field.type === 'secret' ? 'text-white' : null ]">
                    {{ field.data.question }}</p>

                <input-counter :maxlength="field.data.maxlength" :value="inputs[field.type]">
                    <ion-input v-if="field.data.input === 'text'" v-model="inputs[field.type]"
                               class="input-custom bg-grey"
                               :maxlength="field.data.maxlength" @ionBlur="() => saveData()"></ion-input>
                    <ion-textarea v-else-if="field.data.input === 'textarea'" v-model="inputs[field.type]"
                                  class="input-custom bg-grey" @ionBlur="() => saveData()"
                                  :rows="field.data.rows" :maxlength="field.data.maxlength" @ionChange="(event) => checkCharacterLimit(event, inputs[field.type], field.data.maxlength)"></ion-textarea>
                </input-counter>

                <ion-img v-if="field.type === 'why'" src="assets/icon/bloc_heart.png"
                         class="absolute -top-9 right-6 h-20"></ion-img>
                <ion-img v-else-if="field.type === 'with-who'" src="assets/icon/bloc_who.png"
                         class="absolute -top-12 -left-8 h-24"></ion-img>
                <template v-else-if="field.type === 'secret'">
                    <ion-img src="assets/icon/stars1.png" class="absolute -top-7 -left-5 h-20"></ion-img>
                    <ion-img src="assets/icon/stars2.png" class="absolute -top-7 -right-8 h-20"></ion-img>
                </template>
            </div>

            <template v-if="mode === 'modify'">
                <decade-component :activity="activity" :mode="mode" ref="decadeComponent"></decade-component>
                <import-photo-component :activity="activity" :data="activity.data" :mode="mode" ref="importPhotoComponent"></import-photo-component>
            </template>

        </div>

        <div class="bg-secondary text-white text-center px-12 pt-6 pb-24 relative">
            <ion-img src="assets/persona/boy2.png" class="absolute h-20 -top-14 left-[15%] -rotate-12"></ion-img>
            <template v-if="mode === 'modify'">
                Lorsque tu as terminé de modifier ton souvenir, appuie sur “J’ai terminé”
            </template>
            <template v-else>Tu peux maintenant passer à la suite de l’activité en appuyant sur “J’ai terminé” !</template>
        </div>

        <div class="buttons">
            <button-custom v-if="mode !== 'modify'" strong shape="round" color="tertiary" border @click="dontKnow">{{
                    "Je ne sais pas"
                                                                                        }}
            </button-custom>
            <button-custom strong shape="round" size="md" color="primary" border @click="confirmCompleted">{{
                    "J'ai terminé"
                                                                                         }}
            </button-custom>
        </div>
    </div>

</template>
<script>
import { IonInput, IonTextarea, IonImg } from '@ionic/vue';
import ButtonCustom from "@/components/ButtonCustom";
import InputCounter from "@/components/InputCounter";

import openToast from "@/services/ToastService";
import { countTextWithLineBreaks, showFirstError } from "@/services/FormatService";
import { mapGetters } from "vuex";
import { TYPE_VISUAL_CLOUD, TYPE_WORD_CLOUD } from "@/constants/ActivityConstant";
import { alertBox } from "@/services/AlertService";
import DecadeComponent from "@/components/activities/DecadeComponent.vue";
import ImportPhotoComponent from "@/components/activities/ImportPhotoComponent.vue";

export default {
    name: "ModifyComponent",
    components: {
        ImportPhotoComponent,
        DecadeComponent,
        ButtonCustom,
        IonInput,
        InputCounter,
        IonTextarea,
        IonImg
    },
    props: {
        activity: Object,
        data: Object,
        mode: String,
        callback: Function
    },
    data() {
        return {
            activated: false,
            inputs: {},
        }
    },
    mounted() {
        setTimeout(() => this.activated = true, 10);
    },
    watch: {
        data: {
            handler(newData) {
                if (!newData) {
                    return;
                }

                for (const [type] of Object.entries(newData)) {
                    if (this.activity.data && this.activity.data[type]) {
                        this.inputs[type] = this.activity.data[type];
                    }
                    else {
                        this.inputs[type] = null;
                    }
                }
            },
            immediate: true
        }
    },

    computed: {
        ...mapGetters([
            'getActivity'
        ]),
    },
    methods: {

        checkCharacterLimit(event, value, maxChar) {
            const currentCharCount = countTextWithLineBreaks(value);

            if (currentCharCount > maxChar) {
                event.target.value = value.slice(0, value.length - 1);
                value = value.slice(0, value.length - 1);
            }
        },

        saveData: function (loader) {
            this.axios.post('house/' + this.$route.params.house + '/activity/' + this.$route.params.activity + '/data', this.inputs, { noLoader: !loader })
                .then((response) => {
                    this.$store.commit('setActivityData', response.data);
                })
                .catch((error) => {
                    openToast(showFirstError(error?.response?.data?.errors))
                });
        },
        confirmCompleted: function () {

            if (!this.inputs['title']) {
                openToast("Tu dois écrire un nom");
                return;
            }
            else if (!this.inputs['description']) {
                openToast("Tu dois écrire une description");
                return;
            }
            else if (this.mode === 'modify') {
                if (!this.activity.decade) {
                    openToast("Tu dois renseigner l'âge de ton souvenir ou une décennie");
                    return;
                }
            }

            this.saveData(true);
            if (this.mode === 'modify') {
                this.$refs.decadeComponent.saveData();
                this.$refs.importPhotoComponent.importPhoto();
            }
            this.$emit('completed');
        },
        dontKnow: async function() {
            if (this.activity.type === TYPE_WORD_CLOUD || this.activity.type === TYPE_VISUAL_CLOUD) {
                this.$emit('changeMode', 'help');
            }
            else {
                const alert = await alertBox("Tu quittes l'activité " + this.activity.name + ", <br> tu pourras la refaire quand tu le souhaites",
                    null, 'white',
                    [
                        {
                            text: "Revenir sur l'activité",
                            role: 'cancel',
                        },
                        {
                            text: 'Je la ferai plus tard',
                            role: 'confirm',
                        }]
                );

                if (alert.role === 'confirm') {
                    this.$router.back();
                }
            }
        },
        goto: function (id) {
            this.$router.push('/house/' + this.$route.params.house + '/' + this.$route.params.room + '/' + this.$route.params.theme + '/' + this.$route.params.category + '/' + this.$route.params.activity + '/' + id);
        }
    }
}
</script>

<style lang="scss" scoped>

.modify-component {

    @apply w-full flex-1;

    .inputs-container {
        max-width: 70%;
        @apply  mx-auto flex flex-col pt-4 pb-20 translate-y-[100vh];
        transition: transform 0.6s ease-in-out;

        &.activated {
            @apply translate-y-0;
        }

        .input {
            @apply relative px-12 pt-3 pb-10 rounded-2xl shadow-2xl text-center mb-10;

            &.sm {
                max-width: 300px;
            }

            &.xs {
                max-width: 200px;
            }
        }
    }

    .buttons {
        @apply text-center space-x-24 fixed bottom-4 left-0 right-0 z-10;
    }
}

</style>
