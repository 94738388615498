<template>

    <div class="decade-component">

        <div class="inputs-container">

            <div class="input">
                <p class="text-lg text-center block mb-2">
                    Écris ci-dessous l’âge que tu avais dans ce souvenir (même approximatif) :
                </p>

                <ion-input v-model="age" @ionChange="(e) => onChange('age', e)" class="input-custom bg-grey max-w-60 mx-auto my-5" maxlength="3" type="number"></ion-input>

                <p class="text-lg text-center block mb-2">
                    <b>OU</b> la décennie dans laquelle tu étais :
                </p>

                <ion-select v-model="decade"  @ionChange="(e) => onChange('decade', e)" interface="action-sheet" class="bg-primary rounded-lg" placeholder="Clique et choisis une décennie :" cancel-text="Annuler">
                    <ion-select-option value="5">0 - 10 ans</ion-select-option>
                    <ion-select-option value="15">10 - 20 ans</ion-select-option>
                    <ion-select-option value="25">20 - 30 ans</ion-select-option>
                    <ion-select-option value="35">30 - 40 ans</ion-select-option>
                    <ion-select-option value="45">40 - 50 ans</ion-select-option>
                    <ion-select-option value="55">50 - 60 ans</ion-select-option>
                    <ion-select-option value="65">60 - 70 ans</ion-select-option>
                    <ion-select-option value="75">70 - 80 ans</ion-select-option>
                    <ion-select-option value="85">80 - 90 ans</ion-select-option>
                    <ion-select-option value="95">90 - 100 ans</ion-select-option>
                    <ion-select-option value="105">100 - 110 ans</ion-select-option>
                    <ion-select-option value="110">110+ ans</ion-select-option>
                </ion-select>

            </div>

        </div>


        <div v-if="mode !== 'modify'" class="buttons">
            <button-custom strong shape="round" size="md" color="primary" border @click="confirmCompleted">{{
                    "J'ai terminé"
                                                                                                           }}
            </button-custom>
        </div>
    </div>

</template>
<script>
import { IonInput, IonSelect, IonSelectOption } from '@ionic/vue';
import ButtonCustom from "@/components/ButtonCustom";

import openToast from "@/services/ToastService";
import { showFirstError } from "@/services/FormatService";

export default {
    name: "DecadeComponent",
    components: {
        ButtonCustom,
        IonInput,
        IonSelect,
        IonSelectOption
    },
    props: {
        activity: Object,
        mode: String,
        callback: Function
    },
    data() {
        return {
            age: null,
            decade: null,
        }
    },
    mounted() {
        this.age = null;
        this.decade = null;
        if (this.activity.decade) this.age = this.activity.decade;
    },
    computed: {},
    methods: {

        saveData: function () {

            let params = {
                decade: this.age,
            };
            if (this.decade) params.decade = this.decade;

            this.axios.post('house/' + this.$route.params.house + '/activity/' + this.$route.params.activity + '/decade', params)
                .then((response) => {
                    this.$store.commit('setActivity', response.data);
                })
                .catch((error) => {
                    openToast(showFirstError(error?.response?.data?.errors))
                });
        },
        confirmCompleted: function () {
            if (!this.age && !this.decade) {
                openToast("Veuillez renseigner l'âge ou la décennie concernée");
                return;
            }

            this.saveData();
            this.$emit('completed');
        },

        onChange: function(input, event) {
            if (this.mode === 'modify' && event.detail.value != null && event.detail.value !== '' && event.detail.value !== '0' ) { console.log(event)
                if (input === 'age') this.decade = null;
                else if (input === 'decade') this.age = null;
                this.$store.commit('setActivity', { decade: this.decade ?? this.age });
            }
        }

    }
}
</script>

<style lang="scss" scoped>

.decade-component {

    @apply w-full flex-1;

    .inputs-container {
        max-width: 70%;
        @apply mx-auto mt-12 mb-24;


        .input {
            @apply relative px-12 pt-3 pb-10 rounded-2xl shadow-2xl bg-white text-center mb-10;
        }

        ion-select {
            --padding-bottom: 16px;
            --padding-top: 16px;
            --padding-end: 32px;
            --padding-start: 32px;
            --placeholder-color: #FFF;
            --placeholder-opacity: 1;
            &::part(text) {
                @apply text-white text-xl;
            }
            &::part(placeholder) {
                @apply text-white text-lg;
            }
            &::part(icon) {
                @apply text-white opacity-100;
            }
        }
    }

    .buttons {
        @apply text-center space-x-24 fixed bottom-4 left-0 right-0 z-10;
    }
}

</style>
