<template>

    <ion-card>
        <ion-item lines="none" class="card-header">
            <ion-avatar slot="start">
                <ion-img
                    :src="post.user.avatar"></ion-img>
            </ion-avatar>
            <ion-label>
                <h2>{{ post.user.firstname + ' ' + post.user.lastname }}</h2>
                <p>
                    <Timeago refresh locale="fr" :datetime="post.created_at"></Timeago>
                </p>
            </ion-label>
            <ion-button v-if="post.owner" slot="end" fill="clear" @click="displayActions(post)">
                <ion-icon slot="icon-only" :icon="ellipsisVerticalIcon"></ion-icon>
            </ion-button>
        </ion-item>
        <ion-card-content class="body">
            {{ post.body }}
        </ion-card-content>

        <div v-if="post.nb_media > 0" class="images" :class="{ multi: post.nb_media > 1}">
            <template v-for="(img, key) in post.media" :key="key">
                <ion-img :src="img.url" :class="[ key >= 1 ? 'flex-1' : null ]" @click="openLightBox(key)"></ion-img>
            </template>
        </div>
        <div v-else-if="post.activity_id" class="activity" @click="goToActivity(post.activity_id)">
            <ion-img :src="post.activity.icon"></ion-img>
            <div class="activity-name">
                <h2>{{ post.activity.data.title ?? post.activity.name }}</h2>
                <h3>
                    <u>Cliquez ici</u>
                    <ion-icon :icon="playIcon"></ion-icon>
                </h3>
            </div>
        </div>

        <ion-item class="buttons-social" lines="none">
            <span slot="end">{{ post.nb_comments }} {{ post.nb_comments > 1 ? 'commentaires' : 'commentaire' }}</span>
            <button-like slot="end" from-type="post" :from-id="post.id" :count="post.nb_likes"
                         :liked="post.liked" with-count
                         @on-liked="postLiked(post)"></button-like>
        </ion-item>
        <div class="separator"></div>

        <ion-card-content class="responses">
            <template v-if="post.comments && post.comments.length > 0">
                <div v-if="nb_previous_comments > 0" class="text-center">
                    <button-custom :disabled="comments.loading" fill="outline" size="small"
                                   @click="loadComments(comments.meta.current_page + 1)" class="mb-4">
                        Afficher plus de commentaires
                    </button-custom>
                </div>

                <div class="response" v-for="(comment) in post.comments" :key="comment.id">
                    <ion-avatar>
                        <ion-img
                            :src="comment.user.avatar"></ion-img>
                    </ion-avatar>
                    <div class="body-container">
                        <div class="body">
                            <ion-img class="arrow" src="assets/images/bubble-arrow2.png"></ion-img>
                            <div>
                                <ion-label>{{ comment.user.firstname + ' ' + comment.user.lastname }}</ion-label>
                                {{ comment.body }}
                            </div>
                            <div slot="end" class="flex items-center">
                                <button-like from-type="comment" :from-id="comment.id"
                                             :count="comment.nb_likes"
                                             :liked="comment.liked" with-count
                                             @on-liked="commentLiked(comment)"></button-like>
                                <ion-button v-if="comment.user.id === $store.getters.getUser.id" class="btn-action-sheet"
                                            fill="clear" color="white" size="small" @click="displayCommentActions(comment)">
                                    <ion-icon slot="icon-only" :icon="ellipsisVerticalIcon"></ion-icon>
                                </ion-button>
                            </div>

                        </div>
                        <div class="actions">
                            <Timeago refresh locale="fr" :datetime="comment.created_at"></Timeago>
                        </div>
                    </div>

                </div>
            </template>

            <div class="inputs">
                <div class="input">
                    <ion-textarea rows="1" v-model="form.body" placeholder="Répondre ici..."
                                  maxlength="1000" :auto-grow="true"></ion-textarea>
                </div>
                <ion-button fill="clear" class="send">
                    <ion-img slot="icon-only" @click="createComment(post)" src="/assets/icon/icon_send2.png"
                    ></ion-img>
                </ion-button>

            </div>
        </ion-card-content>

    </ion-card>

</template>

<script>
import {
    IonImg,
    IonCard,
    IonCardContent,
    IonLabel,
    IonTextarea,
    IonAvatar,
    IonIcon,
    IonItem,
    actionSheetController,
    IonButton, modalController
} from '@ionic/vue';
import {
    ellipsisVertical as ellipsisVerticalIcon,
    play as playIcon
} from 'ionicons/icons';
import ButtonCustom from "@/components/ButtonCustom";
import ButtonLike from "@/components/ButtonLike";
import { alertBox } from "@/services/AlertService";
import openToast from "@/services/ToastService";
import { showFirstError } from "@/services/FormatService";
import { Timeago } from 'vue2-timeago'

export default {
    name: "CardPost",
    components: {
        ButtonCustom,
        ButtonLike,
        IonButton,
        IonImg,
        IonCard,
        IonCardContent,
        IonLabel,
        IonTextarea,
        IonAvatar,
        IonIcon,
        IonItem,
        Timeago
    },
    props: {
        data: Object,
    },
    data() {
        return {
            playIcon,
            ellipsisVerticalIcon,
            post: this.data,
            nb_previous_comments: 0,
            comments: {
                loading: false,
                data: [],
                meta: {
                    current_page: 0
                }
            },
            form: {
                body: null,
            }
        }
    },
    mounted() {
        if (!this.post.comments) {
            this.post.comments = [];
        }

        if (this.post.latest_comment) {
            this.post.comments.push(this.post.latest_comment);
            this.nb_previous_comments = this.post.nb_comments - 1;
        }

        this.comments.data = this.post.comments;
    },
    methods: {
        openLightBox: function (index) {
            this.$store.dispatch('openLightBox', { images: this.mediaUrl, index: index });
        },

        goToActivity: function (id) {
            modalController.dismiss();
            this.$router.push({
                name: 'activity_read',
                params: { house: this.$store.getters.getHouse.id, activity: id }
            });
        },

        postLiked: function (post) {
            post.liked = !post.liked;
            if (post.liked) {
                post.nb_likes++;
            }
            else {
                post.nb_likes--;
            }
        },

        displayActions: async function (post) {
            const actionSheet = await actionSheetController.create({
                buttons: [
                    {
                        text: 'Supprimer ce post',
                        role: 'destructive',
                        data: {
                            action: 'delete',
                        },
                        handler: () => {
                            this.confirmDeletePost(post);
                        }
                    },
                    {
                        text: 'Annuler',
                        role: 'cancel',
                        data: {
                            action: 'cancel',
                        },
                    },
                ],
            });

            await actionSheet.present();
        },

        confirmDeletePost: async function (post) {
            const alert = await alertBox('La suppression est définitive et supprimera les commentaires de ce post.', 'Confirmez-vous la suppression de ce post ?', null, [

                {
                    'text': 'Annuler',
                    'role': 'cancel',
                },
                {
                    'text': 'Je confirme',
                    'role': 'confirm',
                },
            ]);

            if (alert.role === 'confirm') {
                this.axios.delete('post/' + post.id);
                this.$emit('postDeleted', post);
            }
        },

        displayCommentActions: async function (comment) {
            const actionSheet = await actionSheetController.create({
                buttons: [
                    {
                        text: 'Supprimer ce commentaire',
                        role: 'destructive',
                        data: {
                            action: 'delete',
                        },
                        handler: () => {
                            this.confirmDeleteComment(comment);
                        }
                    },
                    {
                        text: 'Annuler',
                        role: 'cancel',
                        data: {
                            action: 'cancel',
                        },
                    },
                ],
            });

            await actionSheet.present();
        },
        createComment: function () {
            if (this.form.body) {

                let params = new FormData();

                params.append('body', this.form.body);

                this.axios.post('/post/' + this.post.id + '/comment', params)
                    .then((response) => {
                        const comment = response.data.data;
                        this.post.comments.push(comment);
                        this.form.body = null;
                        this.post.nb_comments++;
                    })
                    .catch((error) => {
                        openToast(showFirstError(error.response.data));
                    });
            }
        },
        commentLiked: function (comment) {
            comment.liked = !comment.liked;
            if (comment.liked) {
                comment.nb_likes++;
            }
            else {
                comment.nb_likes--;
            }
        },
        loadComments: function (page) {
            if (!page) {
                page = 0;
                this.comments.loading = true;
                if (this.comments) {
                    this.comments.data = [];
                }
            }

            this.axios.get('post/' + this.post.id + '/comments', {
                params: {
                    page: page,
                    number: 10
                }
            })
                .then((response) => {
                    const data = response.data, ln = data.data.length;

                    if (page) {
                        for (let i = 0; i < ln; i++) {
                            if (page === 1 && this.post.latest_comment && i === 0) {
                                continue; //On retire le premier commentaire si latest_comment est activé afin de ne pas avoir le doublon
                            }
                            this.comments.data.unshift(data.data[i]);
                            this.nb_previous_comments--;
                        }
                    }
                    else {
                        this.comments.data = data.data;
                    }

                    this.comments.meta = data.meta;
                    this.comments.links = data.links;

                    this.comments.loading = false;
                })
                .catch(() => {
                    this.comments.loading = false;
                })
        },
        confirmDeleteComment: async function (comment) {
            const alert = await alertBox('La suppression est définitive et irréversible', 'Confirmez-vous la suppression de ce commentaire ?', null, [

                {
                    'text': 'Annuler',
                    'role': 'cancel',
                },
                {
                    'text': 'Je confirme',
                    'role': 'confirm',
                },
            ]);

            if (alert.role === 'confirm') {
                this.axios.delete('post/comment/' + comment.id);
                if (this.post.nb_comments > 0) this.post.nb_comments--;
                const index = this.post.comments.findIndex(item => item.id === comment.id);
                if (index !== -1) {
                    this.post.comments.splice(index, 1);
                }
            }
        },

    },
    computed: {
        mediaUrl: function () {
            let urls = [];
            if (this.post.media) {
                for (const media of this.post.media) {
                    urls.push(media.url);
                }
            }
            return urls;
        }
    },
}
</script>

<style lang="scss" scoped>
ion-card {
    border-radius: 0;
    box-shadow: rgba(var(--ion-color-primary-dark-rgb), 1) 0px 3px 11px -3px;
}

.card-header {
    --padding-top: 16px;

    ion-avatar {
        width: 60px;
        height: 60px;
    }

    ion-label {
        h2 {
            color: var(--ion-color-primary-dark);
            @apply text-lg font-bold;
        }

        p {
            color: var(--ion-color-primary)
        }
    }
}

ion-card-content.body {
    color: var(--ion-color-primary-dark);
    padding-top: 12px;
    padding-bottom: 0;
    white-space: pre-line;
    @apply text-lg;
}

.images {
    position: relative;
    margin-top: 16px;

    ion-img {
        height: 300px;

        &::part(image) {
            object-fit: cover;
        }
    }

    &.multi {
        @apply flex flex-wrap gap-1;

        ion-img {
            height: 200px;

            &:nth-child(-n+1) {
                height: 300px;
                width: 100%
            }
        }
    }
}

.activity {
    background-color: var(--ion-color-secondary);
    box-shadow: -5px 5px 0px 4px var(--ion-color-secondary-dark);
    color: #fff;
    @apply flex items-center rounded-3xl p-8 mx-8 my-3 cursor-pointer;

    &:hover {
        box-shadow: -3px 3px 0px 4px var(--ion-color-secondary-dark);
        margin-right: 2.5rem;
    }

    ion-img {
        width: 100px;
        @apply mr-4;
    }

    .activity-name {
        @apply text-center flex-1;
        h2 {
            @apply text-2xl text-center font-bold;
        }

        h3 {
            @apply mt-2 text-lg flex items-center justify-center;
            ion-icon {
                @apply text-3xl ml-2;
            }
        }
    }

}

.buttons-social {
    --inner-padding-top: 16x;
    --padding-top: 16px;
    --inner-padding-bottom: 16px;
    color: var(--ion-color-primary);
}

.separator {
    background-color: var(--ion-color-primary);
    width: 90%;
    height: 2px;
    margin: 0 auto;
}

ion-card-content.responses {

    .response {
        @apply flex mb-6 relative;

        .body-container {
            @apply ml-6 flex-1;
            .body {
                background-color: var(--ion-color-primary);
                color: #fff;
                @apply relative flex flex-1 justify-between items-center rounded-4xl py-4 pl-10 pr-3;

                .arrow {
                    position: absolute;
                    top: 18px;
                    left: -15px;
                }

                ion-label {
                    @apply font-bold block mb-3;
                }

                .btn-action-sheet {
                    --padding-start: 0;
                    --padding-end: 0;
                }
            }

            .actions {
                color: var(--ion-color-primary);
                @apply ml-10 text-sm;
            }
        }
    }

    .inputs {
        @apply flex w-full;

        .input {
            background-color: var(--ion-color-primary);
            @apply rounded-3xl flex flex-1 pl-8 mr-1 items-center;

            ion-textarea {
                max-height: 400px;
                color: #fff;
                @apply text-xl;
            }

            ion-icon {
                font-size: 32px;
            }
        }

        .send {
            ion-img {
                height: 40px;
            }
        }
    }
}
</style>
