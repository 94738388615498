import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'

import { IonicVue } from '@ionic/vue';

import axios from 'axios'
import VueAxios from 'vue-axios'
import { register } from 'swiper/element/bundle';
register();

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.scss';
import { goToUserHouse } from "@/services/RoutingService";
import { alertBox } from "@/services/AlertService";
import screenfull from 'screenfull';
import './registerServiceWorker'

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
})
api.interceptors.response.use(
    response => {
        if ( (!response.config.params || !response.config.params.noLoader) && !response.config.noLoader) {
            setTimeout(() => store.commit('setLoader', false), 200);
        }
        return response;
    },
    error => {
        setTimeout(() => store.commit('setLoader', false), 200);
        if (error.response.status === 401) {
            localStorage.removeItem('access_token');
            store.commit('clearAuth');
            router.replace({ name: 'Home' });
        }
        return Promise.reject(error);
    }
);
api.interceptors.request.use(
    config => {
        config.headers = {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Accept': 'application/json',
        }

        if ( (!config.params || !config.params.noLoader) && !config.noLoader) {
            store.commit('setLoader', true)
        }
        return config;
    },
);

router.beforeEach(async (to) => {

    if (!screenfull.isFullscreen) {

        // await alertBox("Afin utiliser Happy Madeleine dans les meilleures conditions possibles, nous vous recommandons de passer en mode plein écran.", '', '',
        //     [
        //         {
        //             text: "Je passe en plein écran",
        //             role: 'confirm',
        //             handler: () => {
        //                 screenfull.request();
        //             }
        //         }
        //     ])
    }

    if (!localStorage.getItem('access_token') && !['Home', 'presentation', 'charte', 'login', 'login_password', 'login_password_reset', 'registration', 'registration_form'].includes(to.name)) {
        if (to.name === 'invitations') {
            localStorage.setItem('invitation', 'OK');
            return { name: 'login' };
        }
        return { name: 'Home' };
    }

    if (localStorage.getItem('access_token') && !store.getters.getUser?.id) {
        await api.get('me', { params: { noLoader: true } })
            .then(response => {
                store.commit('setAuth', {
                    access_token: localStorage.getItem('access_token'),
                    user: response.data.data
                });

                if (['Home', 'login', 'registration', 'registration_form'].includes(to.name)) {
                    goToUserHouse(router, response.data.data);
                }

            }).catch(() => {
                localStorage.removeItem('access_token');
                store.commit('clearAuth');
                router.replace({ name: 'Home' });
            })
    }

    if (store.getters.getUser.id && ['Home', 'login', 'registration', 'registration_form'].includes(to.name)) {
        goToUserHouse(router, store.getters.getUser);
    }

    if (to.params.house) {

        if (!store.getters.getHouse?.id) {
            await api.get('house/' + to.params.house)
                .then(response => {
                    store.commit('setHouse', response.data.data);

                    //Rappel d'abonnement tous les 7 jours
                    const remindSubscription = localStorage.getItem('remindSubscription');
                    if (response.data.data.subscriptionSoonFinished && (!remindSubscription || (remindSubscription <= Date.now() - 3600000 * 24 * 7))) {
                        localStorage.setItem('remindSubscription', Date.now());
                        alertBox("L'abonnement de votre maison Happy Madeleine se termine dans moins de 1 mois ! <br><br> <b>Le " + response.data.data.subscriptionSoonFinished + "</b><br><br>N'oubliez pas renouveler votre abonnement pour garder l'accès à votre maison.", '', '',
                            [
                                {
                                    text: "Je verrai plus tard",
                                    role: 'cancel'
                                },
                                {
                                    text: "Je renouvelle de suite!",
                                    role: 'confirm',
                                    handler: () => {
                                        router.push({
                                            name: 'subscription_choice',
                                            query: { house: to.params.house }
                                        });
                                    }
                                }
                            ])
                    }

                })
                .catch(() => {
                    goToUserHouse(router, store.getters.getUser);
                })
        }

        api.get('house/' + to.params.house + '/notifications/count', {
            params: {
                noLoader: true,
            }
        })
            .then((response) => {
                store.commit('resetNotifications');
                store.commit('setNotifications', response.data);
            })

        store.dispatch('startFetchingNotifications', to.params.house);

    }

    if (to.params.house && store.getters.getHouse?.id && !store.getters.getHouse.subscribed) {
        alertBox('Afin de vous remettre les clés de votre maison, vous devez choisir un abonnement.', null, '',
            [
                {
                    text: 'Je me déconnecte',
                    role: 'cancel',
                    handler: () => {
                        localStorage.removeItem('access_token');
                        store.commit('clearAuth');
                        store.commit('clearHouse');
                        router.push({ name: 'Home' });
                    }
                },
                {
                    text: "Je m'abonne",
                    role: 'confirm',
                    handler: () => {
                        router.push({
                            name: 'subscription_choice',
                            query: { house: store.getters.getHouse.id }
                        });
                    }
                }
            ],
            {
                backdropDismiss: false,
                keyboardClose: false,
            })
    }

});

store.$axios = api;

const app = createApp(App)
    .use(store)
    .use(IonicVue, {mode: "ios"})
    .use(router)
    .use(VueAxios, api);

router.isReady().then(() => {
    window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        const deferredPrompt = e;
        console.log('install', deferredPrompt);
    });

    app.mount('#app');
});
