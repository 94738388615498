<template>
    <ion-header class="ion-no-border lg:py-6 px-6">
        <ion-toolbar class="">
            <ion-buttons slot="start"></ion-buttons>
            <ion-searchbar ref="searchbar" class="main-search" v-model="search" @ionChange="onSearch"
                           placeholder="Rechercher un souvenir ici..."></ion-searchbar>
            <ion-buttons slot="end"></ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <div v-if="loading" class="text-center p-12">
            <ion-spinner></ion-spinner>
        </div>
        <div v-else-if="items.length === 0 && search !== ''" class="text-center p-12 text-gray-500">
            Aucun souvenir avec cette recherche
        </div>
        <div v-else class="grid grid-cols-3 mx-3">
            <div v-for="(item, key) in items" :key="key"
                 class="photo-card"
                 @click="goToActivity(item)">
                <template v-if="item.img">
                    <div class="photo" :style="getPhotoBackground(item.img)"></div>
                    <div class="title">{{ item.title }}</div>
                </template>
                <div v-else class="title">{{ item.title }}</div>
                <p class="font-normal text-xs text-white/50">{{ item.name }}</p>
            </div>
        </div>

    </ion-content>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonSearchbar, IonToolbar,
    IonSpinner,
    modalController
} from '@ionic/vue';
import { defineComponent } from 'vue';

import {
    close as closeIcon,
} from 'ionicons/icons';
import openToast from "@/services/ToastService";
import { TYPE_TIMELINE } from "@/constants/ActivityConstant";

export default defineComponent({
    name: 'ModalSearch',
    components: {
        IonSearchbar,
        IonToolbar,
        IonContent,
        IonHeader,
        IonSpinner,
    },
    data() {
        return {
            items: [],
            search: '',
            closeIcon,
            loading: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.$refs.searchbar.$el.querySelector('input').focus();
        }, 100);
    },
    methods: {

        onSearch: function () {
            if (this.search === '') {
                this.items = [];
                return;
            }

            this.loading = true;
            this.axios.get('/house/' + this.$route.params.house + '/search', {
                params: {
                    search: this.search
                },
                noLoader: true,
            })
                .then(response => {
                    this.items = response.data.data;
                    this.loading = false;
                })
                .catch(() => openToast('Un problème est survenue lors de la recherche.', 'danger'));

        },

        goToActivity: function (item) {
            if (item.type === TYPE_TIMELINE) {
                this.$router.push({
                    name: 'activities_list',
                    params: { house: this.$route.params.house, room: item.room_id, activity: item.default_activity_id },
                    query: { new: item.id }
                })
            }
            else if (this.$store.getters.isHouseOwner) {
                this.$router.push({
                    name: 'activity',
                    params: { house: this.$route.params.house, room: item.room_id, activity: item.id }
                })
            } else {
                this.$router.push({
                    name: 'activity_read',
                    params: { house: this.$route.params.house, activity: item.id }
                })
            }
            this.dismiss();
        },

        dismiss: function () {
            return modalController.dismiss(null, 'cancel');
        },

        getPhotoBackground: function (image) {
            if (image) {
                return { 'backgroundImage': 'url(' + image + ')' };
            }
            return null;
        },
    },

    computed: {}
})
;
</script>

<style lang="scss" scoped>
ion-content, ion-header, ion-toolbar {
    --background: var(--ion-color-tertiary);
    background-color: var(--ion-color-tertiary);
}

ion-item {
    --padding-start: 4px;
}

.photo-card {
    min-height: 250px;
    width: auto;
    z-index: 10;
    @apply flex-1 m-2 cursor-pointer hover:opacity-80;
}
</style>