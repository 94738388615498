<template>

    <div class="step-bar-component" :class="[activated ? 'activated' : null]">
        <span class="text-secondary-dark font-semibold">Étapes</span>
        <div class="bar-container" :class="[step >= 4 ? 'active' : null]">
            <div class="bar-top" :class="[step >= 3 ? 'active' : null]">
                <div class="bar-solid"></div>
            </div>
            <div class="bar-center" :class="[step >= 2 ? 'active' : null]">
                <div class="bar-solid"></div>
            </div>
            <div class="bar-bottom" :class="[step >= 1 ? 'active' : null]">
                <div class="bar-solid"></div>
            </div>
            <div v-if="step >= 4" class="stars">
                <ion-img src="assets/icon/star.svg" class="star absolute top-2 -left-5 h-9"></ion-img>
                <ion-img src="assets/icon/star.svg" class="star absolute top-10 -right-4 h-6"></ion-img>
                <ion-img src="assets/icon/star.svg" class="star absolute top-1/2 -left-2 h-6"></ion-img>
                <ion-img src="assets/icon/star.svg" class="star absolute bottom-24 -right-4 h-8"></ion-img>
                <ion-img src="assets/icon/star.svg" class="star absolute bottom-6 -left-3 h-5"></ion-img>
                <ion-img src="assets/icon/star.svg" class="star absolute bottom-2 -right-6 h-9"></ion-img>
            </div>
        </div>
    </div>

</template>
<script>
import { IonImg } from '@ionic/vue';

export default {
    name: "StepBarComponent",
    components: {
        IonImg
    },
    props: {
        step: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            activated: false,
        }
    },
    mounted() {
        setTimeout(() => this.activated = true, 10);
    },
    watch: {
        step(newStep) {
            if (newStep >= 4) {
                setTimeout(this.startStarBlinking, 300);
            }
        }
    },
    methods: {
        startStarBlinking() {
            const stars = document.querySelectorAll(".stars .star");
            stars.forEach((star) => {
                // Ajoute une animation aléatoire à chaque étoile
                const delay = Math.random() * 2; // Délai aléatoire entre 0 et 2 secondes
                star.style.animationDelay = `${delay}s`;
            });
        }
    }
}
</script>

<style lang="scss" scoped>

.step-bar-component {
    transition: transform 1s ease-in-out;
    z-index: 20;
    @apply w-24 text-center mt-[8vh] -translate-x-24;

    &.activated {
        @apply translate-x-0;
    }

    .bar-container {
        background-color: var(--ion-color-secondary-light);
        height: 60vh;
        @apply w-6 rounded-full mx-auto mt-3 relative;

        &.active {
            @apply outline-4 outline-secondary-dark outline;
        }

        .bar-top,
        .bar-center,
        .bar-bottom {
            @apply w-full h-1/3 bg-transparent overflow-hidden relative;
            .bar-solid {
                @apply absolute left-0 bottom-0 w-full bg-transparent;
                height: 0;
                transform-origin: bottom;
                transition: height 1s ease-in-out;
            }

        }

        .bar-top, .bar-top .bar-solid {
            @apply rounded-t-full;
        }

        .bar-center {
            @apply border-white border-t-4 border-b-4;
        }

        .bar-bottom, .bar-bottom .bar-solid {
            @apply rounded-b-full;
        }

        .active {
            .bar-solid {
                background-color: var(--ion-color-secondary);
                height: 100%;
            }
        }
    }

    .stars {
        .star {
            opacity: 1;
            animation: blink 3s infinite;
        }
    }
}
@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
</style>
