<template>
    <ion-page>
        <nav-bar-book title="Aperçu de mon livre" background-color="white"
                      :show-back-button="false" :show-orders-button="true" :book="book"
                      :nb-pages="totalPages"></nav-bar-book>
        <ion-content :fullscreen="true">
            <div v-if="this.book?.parameters.length > 0" class="h-full w-full bg-primary-light relative">
                <iframe :src="bookPageTemplate" class="w-full h-full mx-auto"></iframe>
                <div class="absolute top-0 bottom-0 left-0 right-0"></div>
            </div>
            <div v-else-if="!loading"
                 class="h-full w-full bg-primary-light relative flex flex-col items-center justify-center">

                <div class="bg-primary text-white font-bold px-6 py-4 rounded-3xl shadow-lg max-w-screen-sm ">
                    Pour prévisualiser et commander ton livre papier, tu dois d'abord sélectionner les souvenirs que tu
                    souhaites voir à l'intérieur.
                    <br><br>
                    Pour cela, tu dois le personnaliser.
                </div>

                <button-custom @click="$router.push({name: 'book_custom'})" color="primary" shape="round" class="mt-6">
                    Personnaliser mon livre
                </button-custom>

            </div>
            <div v-if="loading"
                 class="bg-white/40 absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                <ion-spinner class="size-12"></ion-spinner>
            </div>
        </ion-content>
        <ion-footer >
            <ion-toolbar class="px-8 min-h-[62px]">
                <ion-buttons slot="start" class="my-1 min-w-14" v-show="this.book?.parameters.length > 0">
                    <div v-if="pageDebounce > 1" @click="() => changePage(pageDebounce - 1)"
                         class="cursor-pointer rounded-full bg-primary size-12 flex items-center justify-center ">
                        <ion-icon icon="assets/icon/arrow_right.svg" class="size-8 fill-white rotate-180"></ion-icon>
                    </div>
                </ion-buttons>
                <div class="text-center text-primary-dark font-bold" v-show="this.book?.parameters.length > 0">
                    {{ pageDebounce }} / {{ totalPages }}
                </div>
                <ion-buttons slot="end" class="my-1 min-w-14" v-show="this.book?.parameters.length > 0">
                    <div v-if="pageDebounce < totalPages" @click="() => changePage(pageDebounce + 1)"
                         class="cursor-pointer rounded-full bg-primary size-12 flex items-center justify-center ">
                        <ion-icon icon="assets/icon/arrow_right.svg" class="size-8 fill-white"></ion-icon>
                    </div>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>
    </ion-page>
</template>

<script>
import { IonContent, IonPage, IonFooter, IonSpinner, IonButtons, IonToolbar, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import NavBarBook from "@/components/NavbarBook.vue";
import { API_URL } from "@/constants/UrlConstant";
import ButtonCustom from "@/components/ButtonCustom.vue";

export default defineComponent({
    name: 'BookHomePage',
    components: {
        NavBarBook,
        IonContent,
        IonFooter,
        IonPage,
        IonToolbar,
        IonSpinner,
        IonButtons,
        IonIcon,
        ButtonCustom
    },
    data() {
        return {
            book: null,
            parameters: [],
            pageDebounce: 1,
            debounceTimeout: null,
            page: 1,
            totalPages: 1,
            loading: true,
        }
    },
    ionViewWillEnter() {
        this.loadBook();
        this.page = 1;
        this.pageDebounce = 1;
    },
    methods: {
        loadBook: function () {
            this.loading = true;
            this.axios.get('house/' + this.$route.params.house + '/book')
                .then((response) => {
                    this.book = response.data.data;
                    this.getBookNbPages();
                    if (this.book.parameters.length > 0) {
                        setTimeout(() => this.loading = false, 3000);
                    }
                    else {
                        this.loading = false;
                    }
                });
        },

        getBookNbPages: function () {
            this.axios.get('house/' + this.$route.params.house + '/book/' + this.book.id + '/count-pages')
                .then((response) => {
                    this.totalPages = response.data;
                });
        },

        changePage: function (page) {
            if (page > this.totalPages) {
                return;
            }
            this.loading = true;
            this.pageDebounce = page;
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.page = this.pageDebounce;
                setTimeout(() => this.loading = false, 3000);
            }, 800);

        }
    },
    computed: {
        bookPageTemplate() {
            if (!this.book) {
                return null;
            }

            return API_URL + 'house/' + this.$route.params.house + '/book/' + this.book.id + '/image?page=' + this.page + '&token=' + localStorage.getItem('access_token');
        }
    }
});
</script>

<style scoped>

</style>
