<template>

    <ion-button @click="onClick" :size="size" :shape="shape" :fill="fill" :color="color">
        <slot></slot>
        <input
            ref="uploader"
            type="file"
            :multiple="multiple"
            :accept="accept"
            @change="onFileChanged"
        >
    </ion-button>


</template>

<script>
import { defineComponent, ref } from 'vue';
import { IonButton } from '@ionic/vue';

export default defineComponent({
    name: 'ButtonUpload',
    components: {
        IonButton
    },
    props: {
        size: String,
        shape: String,
        fill: String,
        color: String,
        multiple: Boolean,
        callback: Function,
        accept: String,
    },
    setup(props) {

        const uploader = ref(null);

        const onClick = () => {
            uploader.value.click();
        }

        const getPreview = (image) => {
            return new Promise(function (resolve) {
                let reader = new FileReader();
                reader.onload = function (e) {
                    resolve(e.target.result);
                };
                reader.readAsDataURL(image);
            });
        }

        const onFileChanged = async (e) => {
            let files = e.target.files;
            let dataFile = [];

            if (!files || files.length === 0) return null;

            for (const file of files) {
                let df = { file };
                df.preview = await getPreview(file);
                dataFile.push(df);
            }

            if (props.callback) props.callback(dataFile);

            uploader.value.value = null;
        }

        return {
            uploader,
            onFileChanged,
            onClick
        }
    }
});
</script>

<style lang="scss" scoped>
    input {
        display: none;
    }
    ion-button {
        height: auto;
        --padding-top: 8px;
        --padding-bottom: 8px;
        white-space: normal;

        &.button-large {
            --padding-top: 16px;
            --padding-bottom: 16px;
        }
    }
</style>