<template>
    <ion-header class="ion-no-border ">
        <ion-toolbar class="" color="primary">
            <ion-title class="text-lg">Ajout d'un proche</ion-title>
            <ion-button slot="end" fill="clear" @click="dismiss" size="large" color="white">
                <ion-icon slot="icon-only" :icon="closeIcon"></ion-icon>
            </ion-button>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <h2 class="text-center text-white">Celui-ci pourra voir votre maison et le contenu que vous partagerez</h2>
        <div class=" mx-6 space-y-3 mt-6 mb-6">
            <ion-input class="input-custom" type="email" v-model="form.email" placeholder="Son email*"></ion-input>
            <ion-input class="input-custom" type="text" v-model="form.firstname" placeholder="Son prénom*"></ion-input>
            <ion-input class="input-custom" type="text" v-model="form.lastname" placeholder="Son nom*"></ion-input>
            <ion-input class="input-custom" type="text" v-model="form.link"
                       placeholder="Votre lien. Par exemple, fille"></ion-input>
        </div>
        <div class="bg-tertiary px-10 py-3 grid grid-cols-5 h-1/3">
            <div class="col-span-3">
                <div class="flex items-center">
                    <p class="flex-1">Autoriser cette personne à vous aider à remplir la maison ?</p>
                    <ion-icon :icon="helpOutline" @click="openHelpAdmin = true;"
                              class="cursor-pointer ml-2 bg-primary-dark p-1 rounded-full text-white"></ion-icon>
                </div>
            </div>
            <div class="text-right col-span-2">
                <button-custom :color="form.admin ? 'primary' : 'primary-light'" size="sm" shape="round" strong
                               @click="toggleAdmin(true)">Oui
                </button-custom>
                <button-custom :color="!form.admin ? 'primary' : 'primary-light'" size="sm" shape="round" strong
                               @click="toggleAdmin(false)">Non
                </button-custom>
            </div>
        </div>
        <ion-alert
            :is-open="openHelpAdmin"
            message="Nous vous conseillons d’autoriser au moins un membre de votre famille : il ou elle aura vos accès et pourra remplir des activités, les partager et inviter des proches."
            :buttons="['OK']"
            @didDismiss="openHelpAdmin = false"
        ></ion-alert>
    </ion-content>
    <ion-footer class="text-center pb-3">
        <button-custom color="primary-dark" shape="round" size="md" strong @click="confirmInvitation">Inviter
        </button-custom>
    </ion-footer>
</template>

<script>
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader, IonIcon, IonToolbar, IonTitle, IonAlert, IonInput,
    modalController
} from '@ionic/vue';
import { defineComponent } from 'vue';
import ButtonCustom from "@/components/ButtonCustom.vue";

import {
    close as closeIcon,
    helpOutline
} from 'ionicons/icons';
import { alertBox } from "@/services/AlertService";
import openToast from "@/services/ToastService";
import { showFirstError } from "@/services/FormatService";

export default defineComponent({
    name: 'ModalFamilyInvite',
    components: {
        IonIcon,
        IonFooter,
        IonButton,
        IonToolbar,
        IonContent,
        IonHeader,
        IonTitle,
        ButtonCustom,
        IonAlert,
        IonInput
    },
    props: {
        callback: Function,
        houseId: [String, Number],
    },
    data() {
        return {
            form: {
                email: null,
                firstname: null,
                lastname: null,
                link: null,
                admin: null,
            },
            closeIcon,
            helpOutline,
            loading: false,
            openHelpAdmin: false,
        };
    },
    mounted() {
    },
    methods: {

        dismiss: function () {
            return modalController.dismiss(null, 'cancel');
        },

        toggleAdmin: function (value) {
            this.form.admin = value;
        },

        confirmInvitation: function () {
            if (!this.form.email || !this.form.firstname || !this.form.lastname || !this.form.link) {
                openToast("Tous les champs du formulaire doivent être remplis");
                return false;
            }
            this.axios.post('/house/' + this.houseId + '/family', this.form)
                .then((response) => {
                    if (this.callback) {
                        this.callback(response.data.data);
                    }
                    alertBox("Un mail d'invitation a été envoyé à " + this.form.firstname + " " + this.form.lastname + ". Il(elle) devra confirmer son invitation pour accéder à votre maison.");
                    this.dismiss();
                })
                .catch(error => openToast(showFirstError(error?.response?.data?.errors)));
        }

    },

    computed: {}
})
;
</script>

<style lang="scss" scoped>
ion-content, ion-header, ion-toolbar {
    --background: var(--ion-color-primary);
    background-color: var(--ion-color-primary);
}

ion-footer {
    --background: var(--ion-color-tertiary);
    background-color: var(--ion-color-tertiary);
}
</style>