export function showFirstError(errors) {

    let $return = '';

    if ( typeof errors === 'string' ) {
        return errors;
    }

    if ( typeof errors === 'object' ) {


        for (const prop in errors) {

            if ( typeof errors[prop] === 'object' )

                for (const prop2 in errors[prop]) {

                    if ( typeof errors[prop][prop2] === 'object' )
                        for (const prop3 in errors[prop][prop2])
                            return errors[prop][prop2][prop3];

                    return errors[prop][prop2];
                }

            else
                return  errors[prop];
        }

        return $return;
    }

    return $return;
}

export function countTextWithLineBreaks(text) {
    const maxCharsPerLine = 100; // Le nombre maximal de caractères par ligne
    let totalCharCount = 0; // Compteur total de caractères
    let currentLineLength = 0; // Longueur actuelle de la ligne

    // Supprimer les retours chariot (\r)
    text = text.replace(/\r/g, '');

    // Parcourir chaque caractère du texte
    for (let i = 0; i < text.length; i++) {
        const char = text[i];

        // Si on rencontre un saut de ligne (\n)
        if (char === '\n') {
            // Ajouter les caractères restants pour compléter la ligne à 100
            totalCharCount += maxCharsPerLine - currentLineLength;
            // Réinitialiser la longueur de la ligne après un saut de ligne
            currentLineLength = 0;
        } else {
            // Ajouter un caractère à la ligne
            currentLineLength++;
            totalCharCount++;

            // Si la ligne atteint 100 caractères
            if (currentLineLength === maxCharsPerLine) {
                // Réinitialiser la longueur de la ligne pour commencer une nouvelle ligne
                currentLineLength = 0;
            }
        }
    }

    // Aucun besoin d'ajouter `currentLineLength` à `totalCharCount` ici, car c'est déjà compté correctement
    return totalCharCount;
}