import { toastController } from "@ionic/vue";

export default async function openToast(message, params = {}) {
    const toast = await toastController.create({
        message: message,
        duration: params.duration ?? 5000,
        color: params.color ?? 'danger',
        position: params.position ?? 'bottom',
        buttons: [
            {
                text: "J'ai compris",
                role: 'cancel',

            },
        ],
    });
    return toast.present();
}
