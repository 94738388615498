<template>

    <div class="navbar-vertical">
        <button-icon @click="goHome" icon-url="assets/images/home_icon.png">
            Accueil
        </button-icon>
        <button-icon v-if="showReturnRoom" icon-url="assets/images/return_room.png" @click="goReturnRoom">
            Retour <br>pièce
        </button-icon>
        <button-icon v-if="showHelp" icon-url="assets/images/aide_icon.png">
            Aide
        </button-icon>
        <button-icon v-if="showNote" icon-url="assets/images/note_icon.png">
            Note
        </button-icon>
        <button-icon @click="openModalFeedWall" class="my-family" icon-url="assets/images/messenger-black.png" icon-height="50px">
            Ma famille
            <ion-badge v-if="$store.getters.getNotifications.posts > 0" color="danger">{{ $store.getters.getNotifications.posts }}</ion-badge>
        </button-icon>
    </div>

</template>

<script>
import { IonBadge } from '@ionic/vue';
import { defineComponent } from 'vue';
import ButtonIcon from "@/components/ButtonIcon";
import { openModalFeedWall } from "@/services/ModalService";


export default defineComponent ({
    name: "NavBarVertical",
    components: {
        ButtonIcon, IonBadge
    },
    props: {
        showHelp: Boolean,
        showNote: Boolean,
        showReturnRoom: Boolean,
        roomUrl: String,
    },
    data() {
        return {

        }
    },
    methods: {
        goHome: function() {
            // document.location.href = '/house/'+this.$route.params.house;
            this.$router.push({ name: 'house', params: { house: this.$route.params.house } });
        },
        goReturnRoom: function() {
            this.$router.push(this.roomUrl);
            // document.location.href = this.roomUrl;
        },
        openModalFeedWall: function () {
            openModalFeedWall();
        }
    },
    computed: {

    }
})
</script>

<style lang="scss" scoped>
.navbar-vertical {
    top: 21px;
    right: 32px;
    bottom: 0;
    z-index: 20;
    @apply fixed flex flex-col;

    .my-family {
        position: absolute;
        bottom: 21px;

        ion-badge {
            position: absolute;
            top: 1px;
            right: 10px;
        }
    }
}

</style>
