import { modalController } from "@ionic/vue";
import ModalFeedWall from "@/components/Modal/ModalFeedWall";
import ModalFamilyInvite from "@/components/Modal/ModalFamilyInvite.vue";
import ModalBookPreview from "@/components/Modal/ModalBookPreview.vue";
import ModalCreateHouse from "@/components/Modal/ModalCreateHouse.vue";

export async function openModalFeedWall(postId = null) {
    const modal = await modalController.create({
        component: ModalFeedWall,
        cssClass: 'modal-feed-wall',
        componentProps: {
            toPostId: postId
        },
    });
    return modal.present();
}

export async function openModalFamilyInvite(houseId, callback) {
    const modal = await modalController.create({
        component: ModalFamilyInvite,
        cssClass: 'modal-family-invite',
        componentProps: {
            houseId,
            callback
        },
    });
    return modal.present();
}

export async function openModalBookPreview(bookId, page) {
    const modal = await modalController.create({
        component: ModalBookPreview,
        cssClass: 'modal-feed-wall',
        componentProps: {
            bookId,
            page,
        },
    });
    return modal.present();
}

export async function openModalCreateHouse() {
    const modal = await modalController.create({
        component: ModalCreateHouse,
        cssClass: 'modal-feed-wall',
        componentProps: {

        },
    });
    return modal.present();
}