<template>

    <div class="explanation-component" @click="goto">
        <div class="title">Je t'explique :</div>
        <div v-html="data.text"></div>
        <div class="button" >Appuyez sur l'écran pour continuer</div>
    </div>

</template>
<script>
import {} from '@ionic/vue';

export default {
    name: "ExplanationComponent",
    components: {},
    props: {
        activity: Object,
        data: Object,
        callback: Function
    },
    methods: {
        goto: function () {
            this.callback(1);
        }
    }
}
</script>

<style lang="scss" scoped>

.explanation-component {
    background-color: var(--ion-color-tertiary);
    color: var(--ion-color-primary);
    position: relative;
    width: 50%;
    max-width: 600px;
    @apply mx-auto mt-4 mb-16 p-10 rounded-2xl font-bold text-lg text-center;

    .title {
        background-color: var(--ion-color-primary);
        position: absolute;
        top: -30px;
        left: -40px;
        @apply p-3 text-white font-bold rounded-2xl;
    }

    .button {
        background-color: var(--ion-color-secondary);
        position: absolute;
        bottom: -15px;
        right: 15px;
        color: var(--ion-color-tertiary);
        @apply px-3 py-1 text-sm;
    }
}
</style>
