<template>

    <div class="import-photo-component">
        <div class="photo-card import">
            <div class="photo" :style="getPhotoBackground">
                <button-upload v-if="!photo" :callback="updatePhoto" shape='round' color="primary" size="large"
                               accept="image/*"
                               class="button-edit">
                    Prendre une photo ou l'importer
                </button-upload>
                <button-upload v-else :callback="updatePhoto" shape='round' color="primary" accept="image/*"
                               class="button-edit2">
                    <ion-icon :icon="createIcon" slot="icon-only"></ion-icon>
                </button-upload>
            </div>
        </div>

        <div v-if="mode !== 'modify'" class="buttons">
            <button-custom strong shape="round" color="tertiary" border @click="nextStep">{{ "Je n'en ai pas pour l'instant" }}</button-custom>
            <button-custom strong shape="round" color="primary" border @click="importPhoto">{{ "Valider la photo" }}
            </button-custom>
        </div>
    </div>

</template>
<script>
import { IonIcon } from '@ionic/vue';
import ButtonCustom from "@/components/ButtonCustom";
import ButtonUpload from "@/components/ButtonUpload";
import { create as createIcon } from 'ionicons/icons';

import openToast from "@/services/ToastService";
import { showFirstError } from "@/services/FormatService";
import { mediaUrl } from "@/services/RoutingService";

export default {
    name: "ImportPhotoComponent",
    components: {
        ButtonCustom,
        ButtonUpload,
        IonIcon
    },
    props: {
        activity: Object,
        data: Object,
        mode: String
    },
    data() {
        return {
            createIcon,
            photo: null,
        }
    },
    computed: {
        getPhotoBackground: function () {
            if (this.photo || this.data?.photo) {
                let url = null;
                if (this.photo) {
                    url = this.photo.preview;
                }
                else if (this.data?.photo) {
                    url = mediaUrl('activities/'+this.data.photo);
                }

                return { 'backgroundImage': 'url(' + url + ')' };
            }
            return null;
        }
    },
    methods: {
        updatePhoto: function (photo) {
            this.photo = photo[0];
        },

        importPhoto: function () {
            let formData = new FormData();
            if (!this.photo) {
                if (this.data.photo) {
                    this.$emit('completed');
                    return;
                }
                openToast("Vous devez importer une photo avant de pouvoir valider");
                return;
            }
            formData.append("photo", this.photo.file, this.photo.file.name);
            this.axios.post('house/' + this.$route.params.house + '/activity/' + this.$route.params.activity + '/data', formData)
                .then((response) => {
                    this.$store.commit('setActivityData', response.data);
                    this.$emit('completed');
                }).catch((error) => {
                openToast(showFirstError(error?.response?.data?.errors))
            });
        },
        nextStep: function () {
            this.$emit('completed');
        }
    }
}
</script>

<style lang="scss" scoped>

.import-photo-component {

    @apply flex flex-col flex-1 pb-32;

    .buttons {
        @apply text-center space-x-24 fixed bottom-4 left-0 right-0 z-10;
    }
}

</style>
