<template>
    <ion-header class="ion-no-border ">
        <ion-toolbar class="">
            <ion-button slot="end" fill="clear" @click="dismiss" size="large" color="primary">
                <ion-icon slot="icon-only" :icon="closeIcon"></ion-icon>
            </ion-button>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <div class="max-w-screen-md mx-auto min-h-full flex flex-col items-center justify-center -mt-10">
            <div >
            <bubble-text persona="both">
                Une maison est un abonnement spécifique contenant ces propres souvenirs. Tu devras donc prendre un
                nouvel abonnement.
            </bubble-text>

            <h2 class="text-center text-xl mb-4 mt-8">Donne un nom à ta maison</h2>
            <ion-input class="input-custom" v-model="name" placeholder=""></ion-input>

            <h2 class="text-center text-xl mb-4 mt-8">Quel est ta date de naissance</h2>
            <ion-input class="input-custom" v-model="birthdate" inputmode="date"
                       type="date"></ion-input>

            <div class="text-center mt-6">
                <button-custom shape='round' color="primary" size="large" strong uppercase @click="createHouse" :loader="loading">
                    Confirmer
                </button-custom>
            </div>
            </div>
        </div>

    </ion-content>

</template>

<script>
import {
    IonButton,
    IonContent,
    IonHeader, IonIcon, IonToolbar,
    modalController, IonInput,
} from '@ionic/vue';
import { defineComponent } from 'vue';

import {
    close as closeIcon,
} from 'ionicons/icons';
import BubbleText from "@/components/BubbleText.vue";
import openToast from "@/services/ToastService";
import { goToUserHouse } from "@/services/RoutingService";
import ButtonCustom from "@/components/ButtonCustom.vue";

export default defineComponent({
    name: 'ModalCreateHouse',
    components: {
        ButtonCustom,
        IonInput,
        BubbleText,
        IonButton,
        IonToolbar,
        IonContent,
        IonHeader,
        IonIcon,
    },
    props: {},
    data() {
        return {
            closeIcon,
            loading: false,
            name: null,
            birthdate: null,
        };
    },
    mounted() {
        this.name = "Maison de " + this.$store.getters.getUser?.firstname;
        this.birthdate = this.$store.getters.getUser?.birthdate;
    },
    methods: {

        async createHouse() {
            if (!this.birthdate) {
                openToast('Tu dois renseigner ta date de naissance');
                return;
            }

            if (!this.name) {
                openToast('Tu dois renseigner le nom de ta maison');
                return;
            }

            this.loading = true;



            const house = await this.axios.post('house', {
                name: this.name
            });
            console.log(house);

            await this.axios.post('user', { birthdate: this.birthdate })
                .then((response) => {
                    this.$store.commit('setUser', response.data.data);
                });

            goToUserHouse(this.$router, this.$store.getters.getUser, house.data.data.id);
            await this.dismiss();

            this.loading = false;
        },

        dismiss: function () {
            return modalController.dismiss(null, 'cancel');
        },

    },

    computed: {}
})
;
</script>

<style lang="scss" scoped>

</style>