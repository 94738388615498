<template>

    <div class="button ion-activatable ripple-parent"
         :class="[ size, getLiked ? 'active': null, withCount ? 'with-count': null ]"
         @click="like">
        <span v-if="showLabel" class="mr-2">{{ showLabel ? label : '' }}</span>
        <div class="icon">
            <div class="count" v-if="withCount">{{ getCount }}</div>
            <ion-icon :icon="getLiked ? heartIcon : heartOutlineIcon"></ion-icon>
        </div>
        <ion-ripple-effect></ion-ripple-effect>
    </div>

</template>

<script>
import { defineComponent } from 'vue';
import { IonIcon, IonRippleEffect } from '@ionic/vue';
import { heartOutline as heartOutlineIcon, heart as heartIcon } from 'ionicons/icons';

export default defineComponent({
    name: 'ButtonLike',
    components: {
        IonIcon,
        IonRippleEffect,
    },
    props: {
        fromType: String,
        fromId: [String, Number],
        count: Number,
        liked: Boolean,
        size: {
            type: String,
            default: 'md'
        },
        showLabel: Boolean,
        withCount: Boolean,
    },
    emits: [
        'onLiked',
    ],
    data() {
        return {
            label: "J'aime",
            heartOutlineIcon, heartIcon,
        }
    },
    methods: {
        like: function () {
            let params = {
                from_type: this.fromType,
                from_id: this.fromId,
                noLoader: true,
            };

            this.axios.post('/like', params);
            this.$emit('onLiked');
        }
    },
    computed: {
        getLiked: function () {
            return this.liked;
        },
        getCount: function () {
            return this.count;
        },
    },
});
</script>

<style lang="scss" scoped>
.button {
    border-radius: 22px;
    max-width: 120px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);

    @apply cursor-pointer flex flex-initial justify-center items-center px-4 py-2 text-sm;

    .icon {
        border-radius: 50%;
        font-size: 22px;
        @apply flex items-center justify-center;

        .count {
            @apply text-xl font-bold mr-3;
        }
    }

    &.active {
        .icon {
            ion-icon {
                @apply rotate-6;
            }
        }
    }
}

</style>