<template>

    <ion-item lines="none">
        <slot ></slot>
        <ion-note>{{counter}}</ion-note>
    </ion-item>

</template>

<script>
import { IonNote, IonItem } from '@ionic/vue';
import { countTextWithLineBreaks } from "@/services/FormatService";

export default {
    name: "InputCounter",
    components: {
        IonNote, IonItem
    },
    props: {
        maxlength: [Number, String],
        value: [String, Number],
        countMethod: {
            type: String,
            default: 'with-break' //with-break | normal
        }
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    },
    computed: {
        counter: function() {
            if (!this.value) return this.maxlength;
            const value = String(this.value);
            let ln_value = value.length;
            if (this.countMethod === 'with-break') ln_value = countTextWithLineBreaks(value);
            const count = this.maxlength - ln_value;
            return count < 0 ? 0 : count;
        }
    }
}
</script>

<style lang="scss" scoped>
ion-item {
    position: relative;
    --inner-padding-end: 0;
    --inner-padding-start: 0;
    --padding-end: 0;
    --padding-start: 0;
    --background: none;

    ion-input {
        --padding-end: 32px;
    }

    ion-note {
        position: absolute;
        right: 14px;
        bottom: 12px;
        z-index: 10;
        @apply font-bold;
    }
}

</style>
