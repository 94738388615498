<template>

    <a class="button ion-activatable" :href="href" >
        <ion-img v-if="iconUrl" :src="iconUrl" :style="iconCss"></ion-img>
        <slot></slot>
        <ion-ripple-effect></ion-ripple-effect>
    </a>

</template>

<script>
import { IonImg, IonRippleEffect } from '@ionic/vue';

export default {
    name: "ButtonIcon",
    components: {
        IonImg, IonRippleEffect,
    },
    props: {
        href: String,
        iconUrl: String,
        iconHeight: {
            type: String,
            default: '30px',
        }
    },
    computed: {
        iconCss() {
            return {
                'height': this.iconHeight,
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.button {
    position: relative;
    overflow: hidden;
    width: 90px;
    height: 80px;
    padding: 4px 4px;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    @apply cursor-pointer flex flex-col items-center justify-center leading-none text-xs;

    ion-img {
        @apply mb-2;
    }
}
</style>
