<template>
    <ion-header class="ion-no-border absolute">
        <ion-item lines="none">
            <ion-button slot="end" fill="clear" @click="dismiss" size="large">
                <ion-icon slot="icon-only" :icon="closeIcon"></ion-icon>
            </ion-button>
        </ion-item>
    </ion-header>
    <ion-content class="" ref="content" :fullscreen="true">
        <div class="container-md pt-12">

            <div v-if="posts.loading" class="text-center py-3">
                <ion-spinner></ion-spinner>
            </div>

            <div v-if="posts.data.length === 0" class="text-center p-18 text-xl">
                <h2>Aucun message pour le moment</h2>
            </div>
            <template v-else>

                <template v-for="(post) in posts.data" :key="post.id">

                    <card-post :id="'post-'+post.id" :data="post"
                               @post-deleted="onPostDeleted"></card-post>

                    <div v-if="lastPostViewed.id == post.id" id="new-messages-banner">
                        <div>Messages déjà vus</div>
                    </div>
                </template>

                <div v-if="posts.meta.next" class="text-center pb-6">
                    <button-custom :disabled="posts.loading" fill="outline"
                                   @click="loadPosts(posts.meta.current_page + 1)">
                        Afficher les anciens messages
                    </button-custom>
                </div>

            </template>
        </div>
    </ion-content>
    <ion-footer v-if="!toPostId" class="ion-padding-horizontal ion-no-border">
        <div class="container-md">
            <div v-if="media.length > 0" class="images">
                <div v-for="(img, key) in media" :key="key" class="image">
                    <ion-img :src="img.preview"></ion-img>
                    <ion-button shape="circle" color="dark" @click="deleteImage(key)" size="small">
                        <ion-icon :icon="closeIcon" slot="icon-only"></ion-icon>
                    </ion-button>
                </div>
            </div>
            <div class="inputs">
                <div class="input">
                    <ion-textarea rows="1" v-model="body" placeholder="Ecris un nouveau message ici..."
                                  maxlength="1000" :auto-grow="true"></ion-textarea>
                    <button-upload color="white" fill="clear" :callback="onUpload" multiple>
                        <ion-icon :icon="cameraOutlineIcon" slot="icon-only"></ion-icon>
                    </button-upload>
                </div>
                <ion-button fill="clear" class="send">
                    <ion-img @click="createPost" src="/assets/icon/icon_send.png" class="send"></ion-img>
                </ion-button>
            </div>
        </div>
    </ion-footer>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonFooter,

    IonImg,
    IonItem,
    IonTextarea,
    IonIcon,
    IonButton,
    IonSpinner,
    modalController
} from '@ionic/vue';
import { defineComponent } from 'vue';
import ButtonUpload from "@/components/ButtonUpload";
import ButtonCustom from "@/components/ButtonCustom";
import CardPost from "@/components/CardPost";

import {
    cameraOutline as cameraOutlineIcon,
    close as closeIcon,
} from 'ionicons/icons';
import openToast from "@/services/ToastService";
import { showFirstError } from "@/services/FormatService";

export default defineComponent({
    name: 'ModalFeedWall',
    components: {
        IonContent,
        IonHeader,
        IonFooter,
        CardPost,
        IonImg,
        IonItem,
        IonTextarea,
        IonIcon,
        IonButton,
        IonSpinner,
        ButtonUpload,
        ButtonCustom
    },
    props: {
        toPostId: Number,
        callback: Function
    },
    data() {
        return {
            lastPostViewed: { id: null, date:null },
            posts: {
                data: [],
                meta: {
                    current_page: 0,
                    next: false,
                },
                loading: false,
            },
            body: '...',
            media: [],
            cameraOutlineIcon,
            closeIcon,
        };
    },
    mounted() {
        //Corrige un problème de size du textarea. Ca le force à recalculer sa hauteur.
        setTimeout(() => this.body = null, 200);
        this.loadPosts();
    },
    methods: {

        loadPosts: function (page, ev) {
            if (!page) {
                page = 0;
            }

            this.posts.loading = true;
            this.axios.get('/house/' + this.$route.params.house + '/posts', {
                params: {
                    page: page,
                    noLoader: true,
                    number: 10,
                    latestComment: true,
                    lastPostViewed: this.lastPostViewed.date,
                    orderByColumn: 'upgraded_at',
                    toPostId: this.toPostId,
                }
            }).then((response) => {

                const data = response.data.posts, ln = data.data.length;

                    for (let i = 0; i < ln; i++) {
                        this.posts.data.push(data.data[i]);
                    }

                this.posts.meta = data.meta;
                if (response.data.lastPostViewed && response.data.lastPostViewed.id) {
                    this.lastPostViewed = response.data.lastPostViewed;
                }

                if (ev) {
                    ev.target.complete();
                }

                if (data.data.length <= 0) {
                    if (ev) {
                        ev.target.disabled = true;
                    }
                }

                this.posts.loading = false;


                this.$store.commit('setNotifications', { posts: 0 });
            }).catch(() => {
                if (ev) {
                    ev.target.complete();
                    ev.target.disabled = true;
                }
                this.posts.loading = false;
            })
        },


        onUpload: function (files) {
            this.media = files;
            if (this.media.length > 4) {
                openToast("Vous ne pouvez pas envoyer plus de 4 images par message");
                this.media.splice(4);
            }
        },
        deleteImage: function (id) {
            this.media.splice(id, 1);
        },

        createPost: function () {

            if (this.body) {

                let params = new FormData();

                params.append('body', this.body);

                for (let i = 0; i < this.media.length; i++) {
                    params.append('media[]', this.media[i].file);
                }

                this.axios.post('/house/' + this.$route.params.house + '/post', params)
                    .then((response) => {
                        const post = response.data.data;
                        this.posts.data.unshift(post);
                        this.body = null;
                        this.media = [];

                        if (this.callback) {
                            this.callback(post);
                        }
                        setTimeout(() => this.$refs.content.$el.scrollToTop(50), 200);
                    })
                    .catch((error) => {
                        openToast(showFirstError(error.response.data));
                    });
            }
        },

        onPostDeleted: function (post) {
            const index = this.posts.data.findIndex(item => item.id === post.id);
            if (index !== -1) {
                this.posts.data.splice(index, 1);
            }
        },

        dismiss: function () {
            return modalController.dismiss(null, 'cancel');
        }
    }
})
;
</script>

<style lang="scss" scoped>
ion-content, ion-footer {
    --background: var(--ion-color-tertiary);
    background-color: var(--ion-color-tertiary);
}

.container-md {
    max-width: 72%;
}

ion-header {
    --background: transparent;

    ion-item {
        --background: transparent;

        ion-button {
            --padding-start: 0;
            --padding-end: 0;

            ion-icon {
                @apply text-6xl;
            }
        }

    }

}

#new-messages-banner {
    color: var(--ion-color-secondary);
    border-bottom:4px dashed var(--ion-color-secondary);
    position: relative;
    margin-left: -100px;
    margin-right: -100px;
    @apply mb-12 mt-28;

    div {
        position: absolute;
        top: -14px;
        left: calc(50% - 125px);
        background-color: var(--ion-color-tertiary);
        @apply text-xl px-4 font-bold uppercase;
    }
}

ion-footer {
    @apply py-3;

    .images {
        --padding-end: 0px;
        --padding-start: 0px;
        --inner-padding-end: 8px;
        --inner-padding-start: 8px;
        @apply flex space-x-3 mb-2;

        .image {
            position: relative;
            height: 100px;
            width: 100px;

            ion-img {
                height: 100px;
                width: 100px;
                object-fit: cover;

                &::part(image) {
                    @apply rounded-lg;
                }
            }

            ion-button {
                position: absolute;
                top: 8px;
                right: 8px;
            }
        }
    }

    .inputs {
        @apply flex w-full;

        .input {
            background-color: var(--ion-color-primary-dark);
            @apply rounded-3xl flex flex-1 pl-8 mr-1 items-center;

            ion-textarea {
                max-height: 400px;
                @apply text-xl;
            }

            ion-icon {
                font-size: 32px;
            }
        }

        .send {
            ion-img {
                height: 40px;
            }
        }
    }

}
</style>