<template>

    <div class="word-cloud-component">

        <div class="inputs-container">

            <div v-for="(item, index) in activity.words_cloud" :key="index" @click="selectCloud(item.name)" class="cloud">
                <ion-img v-if="item.img" :src="getUrlImg(item)" class="h-14 mb-1"></ion-img>
                {{ item.name }}
            </div>

        </div>

        <div class="buttons">
            <button-custom strong shape="round" color="tertiary" border @click="dontKnow">{{
                    "Je ne sais pas"
                                                                                          }}
            </button-custom>
        </div>
    </div>

</template>
<script>
import { IonImg } from '@ionic/vue';
import ButtonCustom from "@/components/ButtonCustom";

import { alertBox } from "@/services/AlertService";
import { STORAGE_URL } from "@/constants/UrlConstant";

export default {
    name: "WordCloudComponent",
    components: {
        ButtonCustom,
        IonImg
    },
    props: {
        activity: Object,
        callback: Function
    },
    data() {
        return {}
    },
    mounted() {

    },

    computed: {},
    methods: {

        getUrlImg: function(item) {
            return STORAGE_URL+item.img;
        },

        selectCloud: function (value) {
            this.$store.commit('setActivityData', { title: value });
            this.$emit('changeMode', 'current');
        },
        dontKnow: async function () {

            const alert = await alertBox("Si tu ne trouves pas d'inspiration, <br> tu pourras refaire l'activité quand tu le souhaites",
                null, 'white',
                [
                    {
                        text: "Continuer l'activité",
                        role: 'cancel',
                    },
                    {
                        text: 'Je la ferai plus tard',
                        role: 'confirm',
                    }]
            );

            if (alert.role === 'cancel') {
                this.$emit('changeMode', 'current');
            }
            else if (alert.role === 'confirm') {
                this.$router.back();
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.word-cloud-component {

    @apply w-full py-12 self-start;

    .inputs-container {
        max-width: 70%;
        @apply mx-auto grid grid-cols-3;

        .cloud {
            @apply cursor-pointer bg-primary text-white text-center font-bold px-4 py-2 m-2 rounded-xl border-transparent border-2 hover:shadow-md hover:border-primary-dark hover:border-2;
        }
    }

    .buttons {
        @apply text-center space-x-24 fixed bottom-4 left-0 right-0 z-10;
    }
}

</style>
