<template>

    <div class="modify-frieze-component">

        <div class="inputs-container">

            <div class="input ">
                <p class="text-lg text-center block mb-6 text-primary font-bold">
                    Ajouter un évènement
                </p>

                <div class="flex items-center mb-4">
                    <span class="w-32">Date</span>

                    <input-counter :maxlength="4" :value="decade" class="flex-1">
                        <ion-input v-model="decade" placeholder="Ecris l'année approximative ici"
                                   class="input-custom bg-grey" type="number"
                                   minlength="4" maxlength="4"></ion-input>
                    </input-counter>
                </div>

                <div class="flex mb-4">
                    <span class="w-32 mt-2">Description</span>

                    <input-counter :maxlength="100" :value="inputs['description']" count-method="normal" class="flex-1">
                        <ion-textarea v-model="inputs['description']"
                                      class="input-custom bg-grey" placeholder="Décris l’évènement ici "
                                      maxlength="100"
                                      rows="4"></ion-textarea>
                    </input-counter>
                </div>

                <div class="flex items-center">
                    <span class="w-32">C'était avec</span>

                    <input-counter :maxlength="40" :value="inputs['with-who']" class="flex-1">
                        <ion-input v-model="inputs['with-who']"
                                   class="input-custom bg-grey"
                                   placeholder="Ecris le nom de la personne avec qui tu l'as partagé"
                                   maxlength="40"></ion-input>
                    </input-counter>
                </div>


            </div>

        </div>

        <div class="buttons">
            <button-custom strong shape="round" size="md" color="primary" border @click="confirmCompleted" :loader="loader">
                {{ mode === 'modify' ? "Modifier" : "Ajouter" }}
            </button-custom>
        </div>
    </div>

</template>
<script>
import { IonInput, IonTextarea, } from '@ionic/vue';
import ButtonCustom from "@/components/ButtonCustom";
import InputCounter from "@/components/InputCounter";

import openToast from "@/services/ToastService";
import { showFirstError } from "@/services/FormatService";
import { mapGetters } from "vuex";

export default {
    name: "ModifyFriezeComponent",
    components: {
        ButtonCustom,
        IonInput,
        InputCounter,
        IonTextarea,
    },
    props: {
        activity: Object,
        data: Object,
        mode: String,
        callback: Function
    },
    data() {
        return {
            inputs: {},
            decade: null,
            loader: false,
        }
    },
    mounted() {
        this.decade = null;
        this.loader = false;
        if (this.activity.decade) {
            this.decade = parseInt(this.activity.decade) + (new Date(this.$store.getters.getHouseUser.birthdate)).getFullYear();
        }
    },
    watch: {
        data: {
            handler(newData) {
                if (!newData) {
                    return;
                }

                for (const [type] of Object.entries(newData)) {
                    if (this.activity.data && this.activity.data[type]) {
                        this.inputs[type] = this.activity.data[type];
                    }
                    else {
                        this.inputs[type] = null;
                    }
                }
            },
            immediate: true
        }
    },

    computed: {
        ...mapGetters([
            'getActivity'
        ]),
    },
    methods: {

        async saveData() {
            try {
                this.loader = true;
                const response1 = await this.axios.post(
                    'house/' + this.$route.params.house + '/activity/' + this.$route.params.activity + '/data',
                    this.inputs,
                    { noLoader: true }
                );
                this.$store.commit('setActivityData', response1.data);

                const age = parseInt(this.decade) - (new Date(this.$store.getters.getHouseUser.birthdate)).getFullYear();

                const response2 = await this.axios.post(
                    'house/' + this.$route.params.house + '/activity/' + this.$route.params.activity + '/decade',
                    { decade: age },
                    { noLoader: true }
                );
                this.$store.commit('setActivity', response2.data);

                await this.axios.post(
                    'house/' + this.$route.params.house + '/activity/' + this.$route.params.activity + '/completed'
                    , { noLoader: true });
                this.$store.commit('setActivity', { completed: true });

                this.loader = false;

            }
            catch (error) {
                openToast(showFirstError(error?.response?.data?.errors));
            }
        },

        async confirmCompleted() {
            if (!this.decade) {
                openToast("Tu dois écrire une date");
                return;
            }
            else if (this.decade < 1900 || this.decade > 2100) {
                openToast("La date n'est pas correcte, elle doit correspondre à une année à 4 chiffres");
                return;
            }
            else if (!this.inputs['description']) {
                openToast("Tu dois écrire une description");
                return;
            }

            await this.saveData(true);

            this.$emit('completed');
        }

    }
}
</script>

<style lang="scss" scoped>

.modify-frieze-component {

    @apply w-full flex-1;

    .inputs-container {
        max-width: 70%;
        @apply mx-auto flex flex-col pt-4 pb-20;

        .input {
            @apply relative px-12 pt-3 pb-10 rounded-2xl shadow-2xl mb-10 bg-white;

            &.sm {
                max-width: 300px;
            }

            &.xs {
                max-width: 200px;
            }
        }
    }

    .buttons {
        @apply text-center space-x-24 fixed bottom-4 left-0 right-0 z-10;
    }
}

</style>
