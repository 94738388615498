<template>
    <ion-page>
        <ion-content ref="content" :fullscreen="true" class="relative ">

            <div class="activity" :style="backgroundCss" v-if="activity">

                <div class="activity_container flex flex-col">
                    <div class="grid grid-cols-6 activity-header">
                        <div>
                            <button-return></button-return>
                        </div>

                        <div class="col-span-4">
                            <div class="activity_title">
                                {{ activity.name }}
                            </div>
                        </div>
                        <div></div>
                    </div>

                    <step-bar-component v-if="current_step > 0 && activity.type !== TYPE_TIMELINE" :step="current_step"
                                        class="fixed"></step-bar-component>
                    <nav-bar-vertical showReturnRoom :room-url="returnRoomUrl"></nav-bar-vertical>

                    <transition name="slide-fade">
                        <div class="bubble-container"
                             v-if="getBubbleText">
                            <bubble-text :persona="persona">
                                {{ getBubbleText }}
                            </bubble-text>
                        </div>
                    </transition>

                    <div class="flex-1 flex items-center ">
                        <ion-avatar v-if="$route.query?.mode === 'read' && $store.getters.getHouse">
                            <ion-img :src="$store.getters.getHouse.user.avatar"
                                     alt="Photo de profil"></ion-img>
                        </ion-avatar>

                        <explanation-component v-if="!activity.started" :activity="activity"
                                               :data="{ text: activity.explanation }"
                                               :callback="activityStarted"
                        ></explanation-component>

                        <template v-else-if="activity.type === TYPE_TIMELINE">
                            <modify-frieze-component v-if="current_step === 1 || mode === 'modify'" :activity="activity"
                                                     :data="activity.data"
                                                     :mode="mode"
                                                     @change-mode="changeMode"
                                                     @completed="() => goToActivitiesList(activity)"></modify-frieze-component>

                        </template>
                        <template v-else>
                            <modify-component v-if="mode === 'modify'" :activity="activity" :data="activity.data"
                                              :mode="mode"
                                              @completed="onModifyCompleted"></modify-component>
                            <template v-else-if="current_step === 1">
                                <modify-component v-if="mode === 'current'" :activity="activity" :data="activity.data"
                                                  @change-mode="changeMode"
                                                  @completed="() => changeCurrentStep(2)"></modify-component>
                                <template v-else-if="mode === 'help'">
                                    <word-cloud-component
                                        v-if="activity.type === TYPE_WORD_CLOUD || activity.type === TYPE_VISUAL_CLOUD"
                                        :activity="activity"
                                        @change-mode="changeMode"></word-cloud-component>
                                </template>
                            </template>
                            <decade-component v-else-if="current_step === 2" :activity="activity"
                                              @completed="() => changeCurrentStep(3)"></decade-component>
                            <import-photo-component v-else-if="current_step === 3" :activity="activity"
                                                    :data="activity.data"
                                                    @completed="() => changeCurrentStep(4)"></import-photo-component>

                            <summary-component v-else-if="current_step === 4 || activity.completed" :activity="activity"
                                               :data="activity.data" :default-mode="summaryMode"
                                               @change-mode="changeMode"
                                               @change-step="changeCurrentStep"></summary-component>
                        </template>

                    </div>
                </div>

            </div>

        </ion-content>

    </ion-page>
</template>

<script>
import { IonContent, IonPage, IonAvatar, IonImg } from '@ionic/vue';
import { defineComponent } from 'vue';
import NavBarVertical from "@/components/NavbarVertical";
import BubbleText from "@/components/BubbleText";
import ButtonReturn from "@/components/ButtonReturn";
import ExplanationComponent from "@/components/activities/ExplanationComponent";
import SummaryComponent from "@/components/activities/SummaryComponent";

import { mapGetters } from "vuex";
import ModifyComponent from "@/components/activities/ModifyComponent.vue";
import StepBarComponent from "@/components/activities/StepBarComponent.vue";
import { TYPE_TIMELINE, TYPE_VISUAL_CLOUD, TYPE_WORD_CLOUD } from "@/constants/ActivityConstant";
import WordCloudComponent from "@/components/activities/WordCloudComponent.vue";
import DecadeComponent from "@/components/activities/DecadeComponent.vue";
import ImportPhotoComponent from "@/components/activities/ImportPhotoComponent.vue";
import ModifyFriezeComponent from "@/components/activities/ModifyFriezeComponent.vue";

export default defineComponent({
    name: 'ActivityPage',
    components: {
        ModifyFriezeComponent,
        ImportPhotoComponent,
        DecadeComponent,
        WordCloudComponent,
        StepBarComponent,
        ModifyComponent,
        NavBarVertical,
        IonContent,
        IonPage,
        IonAvatar,
        IonImg,
        BubbleText,
        ButtonReturn,
        ExplanationComponent,
        SummaryComponent,
    },
    data() {
        return {
            current_step: 0,
            mode: 'current',
        }
    },
    mounted() {

    },
    // ionViewWillEnter() {
    //     this.loadActivity();
    //     if (this.$route.params.mode) {
    //         this.mode = this.$route.params.mode;
    //     }
    // },
    watch: {

        '$route.params.activity': {
            immediate: true,
            handler(newId) {
                if (newId && this.$route.name === 'activity') {
                    this.loadActivity();
                    if (this.$route.params.mode) {
                        this.mode = this.$route.params.mode;
                    }
                }
            }
        }
    },
    methods: {
        loadActivity: function () {
            const activityId = this.$route.params.activity;

            this.changeCurrentStep(0);
            this.changeMode('current');
            this.$store.commit('clearActivity');

            this.axios.get('house/' + this.$route.params.house + '/activity/' + activityId, {
                params: {
                    withPost: true,
                }
            })
                .then((response) => {
                    this.$store.commit('setActivity', response.data);
                    if (response.data.completed) {
                        this.changeCurrentStep(0);
                    }
                    else if (response.data.started) {
                        this.changeCurrentStep(1);
                    }
                })
        },

        activityStarted: function () {
            const activityId = this.$route.params.activity;
            this.$store.commit('setActivity', { started: 1 });
            this.changeCurrentStep(1);
            this.changeMode('current');
            this.axios.post('house/' + this.$route.params.house + '/activity/' + activityId + '/started');
        },

        changeCurrentStep: function (step) {
            this.current_step = step;
            this.$refs.content?.$el.scrollToTop(200);
        },
        changeMode: function (mode) {
            this.mode = mode;
            this.$refs.content?.$el.scrollToTop(200);
        },

        onModifyCompleted: function () {
            this.changeCurrentStep(0);
            this.changeMode('view');
        },

        goToActivitiesList: function(activity) {
            this.$router.push({name: 'activities_list', params: { house:  this.$route.params.house, room:  this.$route.params.room, activity: activity.default_activity_id }, query: { new: activity.id }});
        }
    },
    computed: {
        ...mapGetters({
            'activity': 'getActivity',
        }),

        TYPE_TIMELINE() {
            return TYPE_TIMELINE
        },
        TYPE_WORD_CLOUD() {
            return TYPE_WORD_CLOUD
        },
        TYPE_VISUAL_CLOUD() {
            return TYPE_VISUAL_CLOUD
        },

        getBubbleText: function () {
            if (this.mode === 'modify') {
                return "Tu peux modifier ton souvenir ici !";
            }
            else if (this.mode === 'read') {
                return null;
            }
            else if (this.mode === 'current') {
                if (this.current_step === 1) {
                    return this.activity.bubble_text?.main?.value ?? null;
                }
                else if (this.current_step === 2) {
                    return "De quand date ce souvenir ?";
                }
                else if (this.current_step === 3) {
                    return "Tu peux ajouter une photo de ce souvenir, si tu le souhaites.";
                }
                else if (this.current_step === 4) {
                    return "Voici le résumé, est-ce que cela te convient ?";
                }
            }
            else if (this.mode === 'help') {
                if (this.activity.type === TYPE_WORD_CLOUD || this.activity.type === TYPE_VISUAL_CLOUD) {
                    return this.activity.bubble_text.cloud.value ?? null;
                }
            }
            return null;
        },

        returnRoomUrl: function () {
            return '/house/' + this.$route.params.house + '/' + this.$route.params.room;
        },

        summaryMode: function () {
            if (this.mode === 'view') {
                return 'view';
            }
            else if (this.current_step >= 4) {
                return 'summary';
            }
            else if (this.activity.completed) {
                if (this.mode === 'complete') {
                    return 'complete';
                }
                return 'view';
            }
            return this.mode;
        },

        persona: function () {
            if (this.$store.getters.isHouseOwner) {
                return 'boy';
            }
            return 'granny';
        },
        backgroundCss: function () {
            if (this.activity) {
                if (this.activity.started) {
                    let bg = 'background-image: url(' + this.activity.image + '); ';

                    return bg;
                }
                else {
                    return 'background-image: url(' + this.activity.image_explanation + '); background-size:contain; background-position: center bottom;';
                }
            }
            return null;
        }
    }
});
</script>

<style lang="scss" scoped>
.activity {
    position: relative;
    width: 100%;
    min-height: 100%;
    padding-top: 20px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;

    .activity_container {
        position: relative;
        min-height: 98vh;
        padding-top: 100px;

        .activity-header {
            top: 20px;
            @apply w-full fixed z-20;
        }

        .navbar-vertical {
            right: 10px;
        }

        ion-avatar {
            width: 100px;
            height: 100px;
            background-color: #d4dced;
            position: absolute;
            top: 40px;
            right: 110px;
        }

        .activity_title {
            background-color: var(--ion-color-tertiary);
            color: var(--ion-color-primary);
            min-width: 400px;
            @apply mt-2 w-fit mx-auto text-center px-8 py-4 font-bold text-lg rounded-2xl;
        }

    }
}

.bubble-container {
    transform: translateX(0);
    opacity: 1;

    &.slide-fade-enter-active {
        transition: transform 1s ease, opacity 1s ease;
    }

    &.slide-fade-enter, &.slide-fade-enter-from {
        transform: translateX(-100%);
        opacity: 0;
    }

    &.slide-fade-enter-to {
        transform: translateX(0);
        opacity: 1;
    }

    .BubbleText {
        margin: 0 auto;
        max-width: 70%;
        z-index: 5;
    }
}


</style>
